import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, getCurrentUser, signOut, fetchAuthSession } from 'aws-amplify/auth';
import LoginImage from '../../images/login-image.png';
import hbaAcademyLogo from '../../images/HBA-acedemy-logo.png';

const LoginForm = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Check for existing session on component mount
  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      const session = await fetchAuthSession();
      if (session.tokens) {
        const user = await getCurrentUser();
        setUser(user.username);
        localStorage.setItem('LastAuthUser', user.username);
        localStorage.setItem('accessToken', session.tokens.accessToken.toString());
        navigate('/dashboard', { replace: true });
      }
    } catch (error) {
      console.log('No existing session');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      // Attempt to sign in
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password
      });

      if (isSignedIn) {
        // Get user and session info
        const [user, session] = await Promise.all([
          getCurrentUser(),
          fetchAuthSession()
        ]);

        // Store necessary information
        setUser(user.username);
        localStorage.setItem('LastAuthUser', user.username);
        localStorage.setItem('accessToken', session.tokens.accessToken.toString());

        // Redirect to dashboard
        navigate('/dashboard', { replace: true });
      } else {
        // Handle additional authentication steps if needed
        switch (nextStep.signInStep) {
          case 'CONFIRM_SIGN_UP':
            setErrorMessage('Please confirm your email first.');
            navigate('/confirm-signup');
            break;
          case 'RESET_PASSWORD':
            setErrorMessage('Password reset required.');
            navigate('/reset-password');
            break;
          default:
            setErrorMessage('Additional verification required.');
        }
      }
    } catch (error) {
      console.error('Error signing in:', error);
      
      // Handle different error types
      switch (error.name) {
        case 'UserNotConfirmedException':
          setErrorMessage('Please confirm your email first.');
          break;
        case 'UserNotFoundException':
          setErrorMessage('Account not found. Please check your email.');
          break;
        case 'NotAuthorizedException':
          setErrorMessage('Incorrect email or password.');
          break;
        case 'InvalidParameterException':
          setErrorMessage('Please enter a valid email and password.');
          break;
        default:
          setErrorMessage('Error signing in. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#1E3A61]"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left side: Login Form */}
      <div className="lg:w-1/2 flex justify-center items-center bg-white p-8">
        <div className="max-w-lg w-full bg-white p-8">
          <img
            src={hbaAcademyLogo}
            alt="HBApp Logo"
            className="mb-2 w-44 -ml-4"
          />
          <h1 className="text-2xl font-semibold mb-4">
            Master Your Homebuilding <br /> Project With Expert Guidance
          </h1>
          <p className="text-gray-500 mb-6">
            Navigate complexities, avoid costly mistakes, and build with confidence
          </p>

          {errorMessage && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-red-700">{errorMessage}</p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your Email Address"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="px-2 h-12 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter your Password"
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>

            <button
              type="submit"
              className="mt-4 h-12 w-full py-2 px-4 border border-[#1E3A61] text-sm font-medium rounded-md text-white bg-[#1E3A61] hover:bg-white hover:text-[#1E3A61] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={isLoading}
            >
              {isLoading ? 'Logging In...' : 'Log In'}
            </button>
          </form>

          <div className="mt-6 text-center space-y-2">
            <p className="text-sm text-gray-500">
              Don't have an account yet?{' '}
              <a href="/signup" className="text-indigo-600 hover:text-indigo-500">
                Sign up
              </a>
            </p>
            <p className="text-sm">
              <a href="/forgot-password" className="text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Right side: Image */}
      <div
        className="bg-red-100 lg:w-1/2 hidden lg:flex items-center justify-center bg-cover bg-center"
        style={{ backgroundImage: `url(${LoginImage})` }}
      >
        {/* Background image is set via style */}
      </div>
    </div>
  );
};

export default LoginForm;