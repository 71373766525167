import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Home from './home';
import Learn from './components/learn-dashboard/learn';
import Dashboard from './components/dashboard/dashboard';
import Profile from './components/profile/profile';
import LoginForm from './components/authentication/Login';
import SignupForm from './components/authentication/Signup';
import ProtectedRoute from './components/authentication/ProtectedRoute';
import ForgetPassword from './components/authentication/ForgetPassword';
import { AppRoutes } from "./AppRoutes";
import { fetchAuthSession } from 'aws-amplify/auth';

const AppRouter = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkInitialSession();
  }, []);

  const checkInitialSession = async () => {
    try {
      const session = await fetchAuthSession();
      if (session.tokens) {
        const lastAuthUser = localStorage.getItem("LastAuthUser");
        setUser(lastAuthUser);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Initial session check failed:', error);
      setUser(null);
    }
  };

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route 
          path="/"
          element={<Navigate to="/login" replace />} 
        />

        

        <Route 
          path="/login" 
          element={<LoginForm setUser={setUser} />} 
        />

        <Route 
          path="/forgot-password" 
          element={<ForgetPassword setUser={setUser} />} 
        />

        <Route 
          path="/signup" 
          element={<SignupForm setUser={setUser} />} 
        />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard user={user} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile user={user} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/learn/:ModuleNum"
          element={
            <ProtectedRoute>
              <Learn user={user} />
            </ProtectedRoute>
          }
        />

        {/* Catch all unauthorized routes */}
        <Route 
          path="*" 
          element={<Navigate to="/dashboard" replace />} 
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;