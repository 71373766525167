import React, { useState } from 'react';
import hbaLogo from "../../images/HBA-acedemy-logo.png";
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { FaList } from "react-icons/fa";

const Navbar = ({ }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleSignOut = async () => {
      try {
          await signOut({ global: true });
          console.log('Successfully signed out');
          localStorage.clear();
          setIsDropdownOpen(false);
          navigate('/');
      } catch (error) {
          console.error('SignOut Error:', error);
          localStorage.clear();
          navigate('/');
      }
  };

    return (
        <nav className="bg-white border-b border-gray-200 fixed top-0 left-0 right-0 z-10">
            <div className="">
                <div className="flex justify-between items-center px-4 sm:px-6 lg:px-8 max-w-full h-20">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 flex items-center">
                            <img
                                className="h-16 w-auto -ml-5"
                                src={hbaLogo}
                                alt="Logo"
                            />
                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                            <button
                                onClick={() => navigate('/dashboard')}
                                className="mt-2 text-gray-900 inline-flex items-center px-1 pt-1 text-sm font-semibold"
                            >
                                My Learning
                            </button>
                        </div>
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                        <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-md"
                            //onClick={()=>window.location.href = 'https://portal.homebuilding.app/registration'}
                            onClick={()=> window.open('https://portal.homebuilding.app/registration')}
                        >
                            Manage your Project on HBApp
                        </button>
                        {/* Profile dropdown */}
                        <div className="ml-3 relative z-40">
                            <div>
                                <button
                                    onClick={toggleDropdown}
                                    type="button"
                                    className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none"
                                    id="user-menu-button"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                >
                                    <span className="sr-only">Open user menu</span>
                                    {/* <img
                                        className="h-8 w-8 rounded-full"
                                        src="https://placedog.net/100/100"
                                        alt="User"
                                    /> */}
                                    <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
                                     <span className="text-l font-bold text-gray-700"><FaList /></span>
                                    </div>
                                </button>
                            </div>

                            {/* Dropdown menu */}
                            {isDropdownOpen && (
                                <div
                                    className="z-50 origin-top-right absolute right-0 mt-7 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu-button"
                                    onClick={closeDropdown}
                                >
                                    <div className="py-1" role="none">
                                        <a
                                            onClick={() => navigate('/profile')}
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                            role="menuitem"
                                        >
                                            Your Profile
                                        </a>
                                        <a
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                            role="menuitem"
                                            onClick={handleSignOut}
                                        >
                                            Sign out
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;