import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import HBApp from "../../images/HBA-App.jpeg";
import { LuMusic4, LuRocket } from "react-icons/lu";
import { FaTools } from "react-icons/fa";
import { generateClient } from '@aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import m1 from "../../images/thumbnail-1.png";
import m2 from "../../images/thumbnail-2.png";
import m3 from "../../images/thumbnail-3.png";
import m4 from "../../images/thumbnail-1.png";
import m5 from "../../images/thumbnail-1.png";
import m6 from "../../images/thumbnail-1.png";
import { getCurrentUser } from 'aws-amplify/auth';
import module1 from "../../images/module1-cert.png";
import module2 from "../../images/module2-cert.png";
import module3 from "../../images/module3-cert.png";

function Dashboard({ user }) {
  const client = generateClient();
  const navigate = useNavigate();
  const [moduleList, setModuleList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [completedModules, setCompletedModules] = useState(null);
  const [userId, setUserId] = useState(null)
  const canvasRef = useRef(null);
  
  //1. Get User ID from cognito
  useEffect(() => {
    const getUserId = async () => {
      
      const { userId } = await getCurrentUser();
      console.log('User ID (Sub):', userId);
      localStorage.setItem("userId", userId);
      setUserId(userId)

      if(completedModules === null){
        fetchCompletedModules(userId); //Call once
      }
    
    };

    if(userId === null){
      getUserId();
    }
  }, []);

  //2. Query to get user details
  const GET_USERDETAILS_BY_ID_QUERY = `
    query user($id: String) {
      user(id: $id) {
        company {
          id
          name
        }
        defaultCompany {
          id
          name
        }
        id
        email
        firstName
        lastName
        userType
        profilePictureKey
        profilePicture
        businessName
        preferredTimezone
        preferredDateFormat
        isTwoFactorEnabled
        history
        organisations {
          items {
            company {
              id
              name
              representative {
                id
                firstName
                lastName
              }
            }
            userType
            hasPortalAccess
            isDeleted
          }
        }
      }
    }
  `;

  //2. Get user details, store in local storage
  useEffect(() => {
    const getUserById = async (userId) => {
      const isUser = JSON.parse(localStorage.getItem("userDetails"));

      if(!isUser){
        try {
          const response = await client.graphql({
            query: GET_USERDETAILS_BY_ID_QUERY,
            variables: {
              id: userId,
            },
          });
          console.log("UserDetails saved in LS: >>", response?.data?.user);
          const userDetailsTemp = response?.data?.user;

          setUserDetails(userDetailsTemp);
          localStorage.setItem("userDetails", JSON.stringify(userDetailsTemp))
          localStorage.setItem("firstName", userDetailsTemp?.firstName );
          localStorage.setItem("lastName", userDetailsTemp?.firstName );
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      }else{
        console.log("UserDetails already in LS", isUser);
        setUserDetails(isUser)
      }
    };

    const fetchUserDetails = async () => {
      if (userDetails === null) {
        const existingUserId = localStorage.getItem("userId");

        if (existingUserId) {
          await getUserById(existingUserId);
        }
      }
    };

    fetchUserDetails();
  }, [userDetails]);


  //3. Get module list to display in dashboard query
  const GET_MODULES_QUERY = `query listModules {
    modules {
      id
      title
      moduleNumber
      description
      featuredImageUrl
      lessons {
        id
        title
        video
        learningObjectives
        lessonOverview
        keyConcepts
        knowledgeCheck {
          format
          instructions
          nextSteps
        }
      }
    }
  }`;

  //3. Get module list to display in dashboard 
  useEffect(() => {
    const fetchModules = async () => {
      try {
        const result = await client.graphql({
          query: GET_MODULES_QUERY,
        });

        console.log('Module List:', result?.data?.modules);

        // Sort modules by moduleNumber
        const sortedModules = result.data.modules.sort((a, b) => 
          parseInt(a.moduleNumber) - parseInt(b.moduleNumber)
        );
        setModuleList(sortedModules);
        setIsLoading(false);
      } catch (error) {
        if (error.message.includes('Unauthorized') || error.message.includes('No current user')) {
          console.log("Error fetching modules")
        }
      }
    };

    if(moduleList === null){
      fetchModules(); //Call once
    }
  }, []);

  //4. Get completed module list query
  const GET_COMPLETED_MODULES_QUERY = `
    query getCompletedModuleByUser($userId: String!) {
      completedModules(userId: $userId) {
        modules {
          description
          featuredImageUrl
          id
          moduleNumber
          title
          lessons {
            id
            keyConcepts
            learningObjectives
            lessonOverview
            quiz
            title
            video
            knowledgeCheck {
              format
              instructions
              nextSteps
            }
          }
        }
      }
    }
    `;

  //4. Get completed module list
  const fetchCompletedModules = async (userId) => {
    try {
      console.log("UserID", userId)
      const result = await client.graphql({
        query: GET_COMPLETED_MODULES_QUERY,
        variables: { userId: userId }
      });

      console.log('Completed Module List:', result);
      
      if(result?.data?.completedModules?.modules){
        const completedModules = result?.data?.completedModules?.modules;
        var completedModulesId = [];
        completedModules.map(x=> completedModulesId ? completedModulesId = [...completedModulesId, x.id] : x)
        console.log("completedModulesId", completedModulesId)

        setCompletedModules(completedModulesId);
      }else{
        setCompletedModules([]);
      }

      
    } catch (error) {
      console.log("Error fetching modules", error)
    }
  };

  // useEffect(() => {
    

    
  // }, []);

  const moduleImages = {
    1: m1,
    2: m2,
    3: m3,
    4: m4,
    5: m5,
    6: m6
  };

  const capitalize = (str) => {
    if (!str) return ''; // Handle null or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const downloadCertificate = (moduleNum) => {
    // Check if userDetails exists
    if (!userDetails) {
      console.error('User details not available');
      return;
    }

    // Check if canvas ref is available
    if (!canvasRef.current) {
      console.error('Canvas element not found');
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    var imageSrc = moduleNum === 1 ? module1 : moduleNum === 2 ? module2 : module3;
    console.log("imagesrc", imageSrc)
    image.src = imageSrc;

    image.onload = () => {
      try {
        // Set canvas dimensions to the image size
        canvas.width = image.width;
        canvas.height = image.height;
        
        // Draw the image onto the canvas
        ctx.drawImage(image, 0, 0);

        // Set larger font properties for the text
        ctx.font = 'bold 300px serif';
        ctx.fillStyle = '#000000';

        const fullName = `${capitalize(userDetails?.firstName || '')} ${capitalize(userDetails?.lastName || '')}`.trim();
        
        if (!fullName) {
          console.error('User name not available');
          return;
        }

        // Measure the width of the name
        const textWidth = ctx.measureText(fullName).width;

        // Calculate the X position to center the text
        const xPosition = (canvas.width - textWidth) / 2;
        const fontSize = 72;
        const yPosition = (canvas.height / 2 + fontSize / 2) - 100; 
    
        // Draw the name on the certificate
        ctx.fillText(fullName, xPosition, yPosition);

        const imgData = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = imgData;
        link.download = `Homebuilding-certificate-${moduleNum}.png`;

        // Trigger the download
        link.click();
      } catch (error) {
        console.error('Error generating certificate:', error);
      }
    };

    image.onerror = () => {
      console.error('Error loading certificate image');
    };
  };

  const CourseCard = ({ module, onCertificateDownload }) => {
    
    const moduleImage = moduleImages[module?.moduleNumber] || m1;

  

    return (
      <div className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col">
       
      <img src={moduleImage} alt={module?.title} className="w-full h-48 object-cover" />

      {/* Completion Banner - Only shown if completed */}
      {completedModules?.includes(module?.id) && (
        <div className="bg-blue-600 px-4 py-2 flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-green-100 rounded-md px-2 py-1 flex items-center">
              <svg 
                className="w-4 h-4 text-green-600 mr-1" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path 
                  fillRule="evenodd" 
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" 
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-green-600 text-sm font-medium">COMPLETED</span>
            </div>
          </div>
          <button className="text-white flex items-center hover:underline"
            onClick={()=>onCertificateDownload(module?.moduleNumber)}
          >
            View Certification
            <svg 
              className="w-4 h-4 ml-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9 5l7 7-7 7" 
              />
            </svg>
          </button>
        </div>
      )}

      {/* Card Content */}
      <div className="p-4 flex flex-col justify-between flex-grow">
        <div>
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-bold">{module?.title}</h3>
            <Link to={`/learn/${module?.moduleNumber}`} className="text-blue-600 text-lg">
              ↗
            </Link>
          </div>
          <p className="text-gray-500 mb-4">{module?.description}</p>
        </div>
        <div className="mt-auto">
        <Link to={`/learn/${module.moduleNumber}`}>
          <button className=
          {completedModules?.includes(module?.id) 
            ? "w-full bg-gray-300 border border-green-700 text-green-700 py-2 px-4 rounded-md hover:bg-gray-300"
            : "w-full bg-white border border-green-700 text-green-700 py-2 px-4 rounded-md hover:bg-green-100"
          }
          >          
            {completedModules?.includes(module?.id) ? 'Review Course' : 'Start Learning'}
          </button>
        </Link>
        </div>
      </div>
    </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#1E3A61]"></div>
      </div>
    );
  }

  // If no modules are found
  if (!moduleList || moduleList.length === 0) {
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar user={user} />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center">
            <h2 className="text-xl text-gray-600">No modules available at the moment.</h2>
            <p className="mt-2 text-gray-500">Please check back later.</p>
          </div>
        </div>
      </div>
    );
  }



  return (
    <div className="min-h-screen flex flex-col">
      <Navbar user={user} />

      <canvas 
          ref={canvasRef}
          style={{ display: 'none' }}
          aria-hidden="true"
        />
      
      <div className="py-12 mt-20">
        <div className="text-center mb-8">
          <h2 className="text-blue-900 font-semibold text-md uppercase">
            Welcome {localStorage.getItem("firstName") ? localStorage.getItem("firstName") : ""}!
          </h2>
          <h1 className="text-4xl font-bold mb-4 mt-2">Let's start building your knowledge today</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Gain the skills and confidence to manage your home building project like a pro.
          </p>
        </div>

        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl mx-auto">
            {moduleList.map((module) => (
              <CourseCard
                key={module.id}
                module={module}
                onCertificateDownload={downloadCertificate}
              />
            ))}
          </div>
        </div>

        <div className="py-16 bg-white flex justify-center">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-4 md:px-20">
              <div className="flex items-start space-x-4">
                <div className="p-4 bg-indigo-100 rounded-lg">
                  <LuRocket />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">Tailored Learning for Every Stage</h3>
                  <p className="mt-2 text-sm text-gray-600">
                    Whether you're planning your dream home or overseeing multiple projects, our content helps you get there.
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="p-4 bg-indigo-100 rounded-lg">
                  <FaTools />
                </div>
                <div>
                  <h3 className="text-lg font-semibold">Track Your Progress</h3>
                  <p className="mt-2 text-sm text-gray-600">
                    Watch your expertise grow as you complete modules and apply your knowledge to real-world scenarios.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-16 text-center">
              <h2 className="text-3xl font-semibold text-gray-900">
                Start Your Journey: From Learning to Building!
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-600">
                Learn here, apply it with HBApp.
              </p>
              <div className="mt-8">
                <a
                  href="https://homebuilding.app/"
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-700 hover:bg-green-700 focus:outline-none"
                >
                  Get Started with HBApp Now!
                </a>
              </div>

              <div className="mt-12 flex justify-center">
                <img 
                  src={"https://mma-public-assets-dev.s3.ap-southeast-1.amazonaws.com/HBA-App.jpeg"} 
                  alt="HBApp" 
                  className="w-full max-w-4xl h-auto rounded-lg shadow-lg" 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;